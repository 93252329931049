<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-11 20:25:41
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-17 14:08:18
 * @version: 
 * @Description: 搜索结果页
-->
<template>
  <section class="container">
    <div class="content-wrap">
      <div class="content">
        <Banner :banners="banners" :loadingBanner="loadingBanner"></Banner>
        <div class="catleader">
          <h1 v-html="cateName"></h1>
        </div>
        <a-skeleton
          :loading="loadingList"
          :active="loadingList"
          :paragraph="{ rows: 10 }"
        >
          <template v-if="listTotal > 0">
            <article
              class="excerpt excerpt-1"
              v-for="(item, index) of results"
              :key="index"
            >
              <a class="focus" :title="item.title" @click="toDetail(item.id)">
                <img
                  :alt="item.title"
                  :src="'https://assets.hzwlb.org' + item.thumb"
                  class="thumb img-responsive"
                  style="display: inline"
                />
              </a>
              <header>
                <a class="cat" href="/news/mxdt.html" title="明星动态"
                  >明星动态<i></i
                ></a>
                <h2>
                  <a :title="item.title" @click="toDetail(item.id)">{{
                    item.title
                  }}</a>
                </h2>
              </header>
              <p class="meta">
                <time><i class="fa fa-clock-o"></i>{{ item.publish_time }}</time
                ><span class="pv"
                  ><i class="fa fa-eye"></i>阅读({{ item.reading }})</span
                >
              </p>
              <p class="note">
                <a href="/news/11/236.html" :title="item.title">
                  {{ description(item.introduction) }}...
                </a>
              </p>
            </article>
          </template>
          <div class="panel-body" v-else>暂时没有找到</div>
        </a-skeleton>
        <!-- 分页 -->
        <div class="cl pd-5 bg-1 bk-gray mt-20">
          <el-pagination
            background
            class="pagination"
            layout="prev, pager, next,total"
            :total="listTotal"
            :page-size="limit"
            :page-count="pageCount"
            :current-page="currentPage"
            @size-change="hanldeSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="sidebar">
      <a-skeleton
        :loading="loadingSticky"
        :active="loadingSticky"
        :paragraph="{ rows: 5 }"
      >
        <Sidebar siderName="置顶推荐" :dataList="Sticky"></Sidebar>
      </a-skeleton>
      <a-skeleton
        :loading="loadingRand"
        :active="loadingRand"
        :paragraph="{ rows: 5 }"
      >
        <Sidebar siderName="随机推荐" :dataList="Rand"></Sidebar
      ></a-skeleton>
    </div>
  </section>
</template>
<script>
import Banner from "@/components/category/banner/Index.vue";
import Sidebar from "@/components/common/sidebar/Index.vue";
import { Message } from "element-ui";
import {
  getBanner,
  getSearch,
  getStickyList,
  getRandList,
} from "@/request/index.js";
// import bus from "@/bus/index.js";
export default {
  name: "Search",
  components: { Banner, Sidebar },
  data() {
    return {
      banners: [],
      loadingList: true,
      loadingBanner: true,
      loadingSticky: true,
      loadingRand: true,
      // articleList: [],
      limit: 4,
      currentPage: 1,
      listTotal: 0,
      cateIDsidebar: 0,
      Sticky: [],
      Rand: [],
      searchKeyword: "",
      results: [],
    };
  },
  computed: {
    cateName() {
      return (
        "搜索<span style='color:red'>" + this.searchKeyword + "</span>结果"
      );
    },
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    description() {
      return (desc) => {
        return desc.substring(0, 160);
      };
    },
    pageCount() {
      return Math.ceil(this.listTotal / this.limit);
    },
  },
  methods: {
    reloadPage() {
      //这接收传递来的关键词

      // bus.$on("keyword", (data) => {
      //   console.log("监听到得搜索词是：" + data);
      //   this.searchKeyword = data;
      // });

      console.log("接收的关键词：" + this.$route.query.searchTxt);
    },

    //进入文章详情
    toDetail(id) {
      // console.log("文章ID：" + id);
      this.$router.push({ name: "Detail", params: { id } });
    },
    hanldeSizeChange(size) {
      this.limit = size;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      //  点击翻页/页码后 请求数据
      this.loadingList = true;
      getSearch(this.searchKeyword, this.limit, this.offset)
        .then((res) => {
          this.results = res.data;
          this.listTotal = res.count;
          this.loadingList = false;
        })
        .catch((err) => {
          Message.error(err.status + "|" + err.statusText);
          console.log(err);
          this.loadingList = false;
        });
    },
  },
  watch: {
    $route: {
      handler: "reloadPage",
    },
  },
  created() {
    console.log("获取搜索词是：" + this.$route.query.searchTxt);
    this.searchKeyword = this.$route.query.searchTxt;
    //在这将关键词提交给后台去查询
    this.loadingList = true;
    getSearch(this.searchKeyword, this.limit, this.offset)
      .then((res) => {
        this.results = res.data;
        this.listTotal = res.count;
        this.loadingList = false;
      })
      .catch((err) => {
        Message.error(err.status + "|" + err.statusText);
        console.log(err);
        this.loadingList = false;
      });
    //加载置顶推荐
    this.loadingSticky = true;
    getStickyList(this.cateIDsidebar, this.limit / 2, this.offset)
      .then((res) => {
        this.Sticky = res.data;
        this.loadingSticky = false;
      })
      .catch((err) => {
        console.log("err " + err);
        Message.error("loadingSticky created" + err);
        this.loadingSticky = false;
      });
    /* 加载侧栏 随机数据*/
    this.loadingRand = true;
    getRandList(this.cateIDsidebar, this.limit / 2, this.offset)
      .then((res) => {
        this.Rand = res.data;
        this.loadingRand = false;
      })
      .catch((err) => {
        console.log("err " + err);
        Message.error("loadingRand created" + err);
        this.loadingRand = false;
      });
    /* 加载Banner数据 */
    this.loadingBanner = true;
    getBanner()
      .then((res) => {
        this.banners = res.data;
        this.loadingBanner = false;
      })
      .catch((err) => {
        console.log("err " + err);
        Message.error("loadingBanner created" + err);
        this.loadingList = false;
      });
  },
};
</script>
<style>
</style>